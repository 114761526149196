var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c(_setup.ElTable,{staticClass:"creators-table",staticStyle:{"width":"100%"},attrs:{"data":_setup.items,"default-sort":{ prop: 'impressions', order: 'descending' }},on:{"sort-change":_setup.onSortChange}},[_c(_setup.ElTableColumn,{attrs:{"label":_setup.locale.creator},scopedSlots:_vm._u([{key:"default",fn:function({ row, $index }){return [_c('div',{staticClass:"creators-table__creator"},[_c('div',{staticClass:"creators-table__creator-index"},[_vm._v(" "+_vm._s(_setup.getRealRowIndex($index))+" ")]),_c('div',{staticClass:"creators-table__creator-image",style:({ backgroundImage: 'url(' + row.image + ')' })}),_c('div',{staticClass:"creators-table__creator-name"},[_vm._v(" "+_vm._s(row.name)+" ")]),_c('div',{staticClass:"creators-table__creator-platform"},[_c('SvgIcon',{directives:[{name:"show",rawName:"v-show",value:(row.platform === 'twitch'),expression:"row.platform === 'twitch'"}],attrs:{"name":"twitch-icon","width":"16","height":"16"}}),_c('SvgIcon',{directives:[{name:"show",rawName:"v-show",value:(row.platform === 'youtube'),expression:"row.platform === 'youtube'"}],attrs:{"name":"youtube-icon","width":"16","height":"16"}}),_c('SvgIcon',{directives:[{name:"show",rawName:"v-show",value:(row.platform === 'trovo'),expression:"row.platform === 'trovo'"}],attrs:{"name":"trovo-icon","width":"16","height":"16"}})],1)])]}}])}),_c(_setup.ElTableColumn,{attrs:{"label":_setup.locale.impressions,"prop":"impressions","sortable":"custom","formatter":(row, column, cellValue, index) => {
        return _setup.numberToRanks(cellValue)
      }}}),_c(_setup.ElTableColumn,{attrs:{"label":_setup.locale.clicks,"prop":"clicks","sortable":"custom","formatter":(row, column, cellValue, index) => {
        return _setup.numberToRanks(cellValue)
      }}}),_c(_setup.ElTableColumn,{attrs:{"label":_setup.locale.ctr,"prop":"ctr","sortable":"custom","formatter":(row, column, cellValue, index) => {
        if (!cellValue) {
          return 0
        }
        return parseFloat((cellValue || 0) * 100).toFixed(2) + '%'
      }}})],1),_c(_setup.ElPagination,{attrs:{"layout":"prev, pager, next","prev-text":_setup.locale.prev,"next-text":_setup.locale.next,"page-size":_setup.creators.perPage,"current-page":_setup.props.filters.page,"total":_setup.creators.total,"hide-on-single-page":""},on:{"current-change":_setup.onPageChange}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }