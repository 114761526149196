<template>
  <transition
    name="el-loading-fade"
    @after-leave="handleAfterLeave"
  >
    <div
      v-show="visible"
      class="el-loading-mask"
      :style="{ backgroundColor: background || '' }"
      :class="[customClass, { 'is-fullscreen': fullscreen }]"
    >
      <div :class="loadingSpinnerClasses">
        <svg
          v-if="!spinner"
          class="circular"
          viewBox="25 25 50 50"
        >
          <circle
            class="path"
            cx="50"
            cy="50"
            r="20"
            fill="none"
          />
        </svg>
        <i
          v-else
          :class="spinner"
        />
        <p
          v-if="text"
          class="el-loading-text"
        >
          {{ text }}
        </p>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  data () {
    return {
      text: null,
      spinner: null,
      background: null,
      fullscreen: true,
      visible: false,
      customClass: '',
      floatLoading: false,
    }
  },

  computed: {
    loadingSpinnerClasses ({ floatLoading }) {
      return [
        'el-loading-spinner',
        { 'is-float-spinner': floatLoading },
      ]
    },
  },

  methods: {
    handleAfterLeave () {
      this.$emit('after-leave')
    },
    setText (text) {
      this.text = text
    },
  },
}
</script>
