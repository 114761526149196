var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c(_setup.ElTable,{staticStyle:{"width":"100%"},attrs:{"data":_setup.filteredData,"default-sort":{ prop: 'ctr', order: 'descending' }}},[_c(_setup.ElTableColumn,{attrs:{"label":_setup.locale.groupName,"prop":"group_name"}}),_c(_setup.ElTableColumn,{attrs:{"label":_setup.locale.creativeName,"prop":"creative_name"}}),_c(_setup.ElTableColumn,{attrs:{"label":_setup.locale.adFormat,"prop":"format"}}),_c(_setup.ElTableColumn,{attrs:{"label":_setup.locale.impressions,"prop":"impressions","sortable":"","formatter":(_row, _column, cellValue, _index) => {
        return _setup.numberToRanks(cellValue)
      }}}),_c(_setup.ElTableColumn,{attrs:{"label":_setup.locale.reach,"prop":"reach","sortable":"","formatter":(_row, _column, cellValue, _index) => {
        return _setup.numberToRanks(cellValue)
      }}}),_c(_setup.ElTableColumn,{attrs:{"label":_setup.locale.clicks,"prop":"clicks","sortable":"","formatter":(_row, _column, cellValue, _index) => {
        return _setup.numberToRanks(cellValue)
      }}}),_c(_setup.ElTableColumn,{attrs:{"label":_setup.locale.ctr,"prop":"ctr","sortable":"","formatter":(_row, _column, cellValue, _index) => {
        return +cellValue.toFixed(2) + '%'
      }}}),_c(_setup.ElTableColumn,{attrs:{"label":_setup.locale.spent,"prop":"spent","sortable":"","formatter":(_row, _column, cellValue, _index) => {
        return _setup.floatToCurrency(+cellValue, false, _setup.currencyCode)
      }}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }