import { Api } from 'lib/api/instance'
import parseSlug from 'lib/helpers/parseSlug'

const defaultState = {
  campaignSlug: '',
  campaignType: null,
  activeTab: 'overview', // overview , creators, categories
  campaignInfo: {
    title: '',
    status: false,
    updatedAt: '',
  },
  campaigns: {
    loading: false,
    title: '',
    status: false,
    updatedAt: '',
    start: '',
    end: '',
    impressions: 0,
    impressions_limit: 0,
    spent: 0,
    spent_limit: 0,
    ctr: 0,
    clicks_total: 0,
    fills: 0,
    reach: 0,
    channels: 0,
    categories: 0,
    avg_cpm: 0,
    data: [],
    creatives: [],
    top_channels: [],
    top_categories: [],
  },
  creators: {
    loading: false,
    title: '',
    status: false,
    updatedAt: '',
    start: '',
    end: '',
    per_page: 0,
    total: 0,
    creators: [],
  },
  categoriesImpressions: {
    loading: false,
    title: '',
    status: '',
    updatedAt: '',
    start: '',
    end: '',
    categories: [],
  },
  categoriesDistribution: {
    loading: false,
    categories: [],
  },
}

const getDefaultState = () => {
  return JSON.parse(JSON.stringify(defaultState))
}

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    setCampaigns: (state, payload) => {
      state.campaigns = {
        loading: false,
        ...payload,
      }
    },
    setCreators: (state, payload) => {
      state.creators = {
        loading: false,
        ...payload,
      }
    },
    setCategoriesImpressions: (state, payload) => {
      state.categoriesImpressions = {
        loading: false,
        ...payload,
      }
    },
    setCategoriesDistribution: (state, payload) => {
      state.categoriesDistribution = {
        loading: false,
        categories: payload,
      }
    },
    setCampaignInfo: (state, payload) => {
      state.campaignInfo = {
        title: payload.title,
        status: payload.status,
        updatedAt: payload.updatedAt,
      }
    },
    setActiveTab: (state, payload) => {
      state.activeTab = payload
    },
    setCampaignSlug: (state, payload) => {
      state.campaignSlug = payload
    },
    setCampaignType: (state, payload) => {
      state.campaignType = payload
    },
    resetState: (state) => {
      Object.assign(state, getDefaultState())
    },
  },
  actions: {
    async fetchCampaigns ({ commit, state }, params) {
      try {
        state.campaigns.loading = true
        const slug = state.campaignSlug
        const res = await Api.get(`statistic/campaigns/${state.campaignType}/campaigns/${slug}/${params.start}/${params.end}`)
        commit('setCampaigns', res.data)
        commit('setCampaignInfo', res.data)
      }
      finally {

      }
    },
    async fetchCreators ({ commit, state }, params) {
      let filtersString = ''

      const directions = {
        descending: 'desc',
        ascending: 'asc',
      }

      if (params.sortBy && params.sortDirection) {
        filtersString = `&sortBy=${params.sortBy}&sortDirection=${directions[params.sortDirection]}`
      }

      try {
        state.creators.loading = true
        const slug = state.campaignSlug
        const res = await Api.get(`statistic/campaigns/${state.campaignType}/creators/${slug}/${params.start}/${params.end}?page=${params.page}${filtersString}`)
        commit('setCreators', { ...res.data, perPage: res.per_page, total: res.total })
        commit('setCampaignInfo', res.data)
      }
      finally {

      }
    },
    async fetchCategoriesImpressions ({ commit, state }, params) {
      try {
        state.categoriesImpressions.loading = true
        const slug = state.campaignSlug
        const res = await Api.get(`statistic/campaigns/${state.campaignType}/categories/${slug}/${params.start}/${params.end}?page=${params.page}`)
        commit('setCategoriesImpressions', { ...res.data, perPage: res.per_page, total: res.total })
        commit('setCampaignInfo', res.data)
      }
      finally {

      }
    },
    async fetchCategoriesDistribution ({ commit, state }, params) {
      try {
        state.categoriesDistribution.loading = true
        const slug = state.campaignSlug
        const res = await Api.get(`statistic/campaigns/${state.campaignType}/categories/distribution/${slug}/${params.start}/${params.end}`)
        commit('setCategoriesDistribution', res.data)
      }
      finally {

      }
    },
    setActiveTab: ({ commit }, tab) => {
      commit('setActiveTab', tab)
    },
    setCampaignSlug: ({ commit }, slug) => {
      commit('setCampaignSlug', slug)
    },
    setCampaignType: ({ commit }, slug) => {
      const { type } = parseSlug(slug)

      switch (type) {
      case 'brand_awareness':
        commit('setCampaignType', 'brand-awareness')
        break
      case 'performance':
        commit('setCampaignType', 'performance')
        break
      }
    },
    resetState: ({ commit }) => {
      commit('resetState')
    },
    downloadReport: ({ state }, params) => {
      const docType = params.docType ? `${params.docType}/` : ''
      const slug = state.campaignSlug
      window.open(`${process.env.VUE_APP_API_URL}statistic/campaigns/${state.campaignType}/${params.type}/${slug}/${params.start}/${params.end}/${docType}download`)
    },
  },
  getters: {
    activeTab: ({ activeTab }) => activeTab,
    campaignSlug: ({ campaignSlug }) => campaignSlug,
    campaignInfo: ({ campaignInfo }) => campaignInfo,
    campaigns: ({ campaigns }) => campaigns,
    creators: ({ creators }) => creators,
    categoriesImpressions: ({ categoriesImpressions }) => categoriesImpressions,
    categoriesDistribution: ({ categoriesDistribution }) => categoriesDistribution,
  },
}
